import React from 'react'

import { CaseContent } from '../../../@import-types/contentful/Case.types'
import ExternalLink from '../../../components/_elements/ExternalLink'
import MonthDate from '../../../components/_elements/MonthDate'
import LayoutContainer from '../../../components/_layouts/LayoutContainer'
import SideColumnLayout from '../../../components/_layouts/SideColumnLayout'
import SvgArrowRightSmall from '../../../components/_svg/SvgArrowRightSmall'
import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'

import styles from './case-info-and-link.module.scss'

interface CaseInfoAndLinkProps {
  caseContent: CaseContent
}

export default function CaseInfoAndLink({ caseContent }: CaseInfoAndLinkProps) {
  const { launchDate, projectLink } = caseContent

  const viewSiteLabel = useMicroCopy('text.view_site')

  if (!projectLink) {
    return null
  }

  return (
    <LayoutContainer>
      <SideColumnLayout
        sideColumn={
          launchDate && <MonthDate className={styles.date} date={launchDate} />
        }
        mainColumn={
          <ExternalLink
            className={styles.link}
            to={projectLink}
            segmentdata={{
              anchor_text: viewSiteLabel,
              position: 'body',
              url: projectLink,
            }}
          >
            {viewSiteLabel} <SvgArrowRightSmall className={styles.linkIcon} />
          </ExternalLink>
        }
      />
    </LayoutContainer>
  )
}
