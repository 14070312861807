import React from 'react'

import { formatMonthDate } from '../../../utils/dateUtils'
import BaseDate from '../BaseDate'

interface MonthDateProps {
  date: Date | string | null | undefined
}

export default function MonthDate({
  date,

  ...baseDateProps
}: MonthDateProps & Omit<React.ComponentProps<typeof BaseDate>, 'formatDate'>) {
  return (
    <BaseDate date={date} formatDate={formatMonthDate} {...baseDateProps} />
  )
}
