import classNames from 'classnames'
import Img from 'gatsby-image'
import React from 'react'

import { CaseContent } from '../../@import-types/contentful/Case.types'
import useMicroCopy from '../../hooks/globalContent/useMicroCopy'
import useCaseLink from '../../hooks/routing/useCaseLink'
import { InternalLink } from '../_elements/InternalLink/InternalLink'
import LayoutContainer from '../_layouts/LayoutContainer'
import SvgArrowRight from '../_svg/SvgArrowRight'

import styles from './next-case-link.module.scss'

interface NextCaseLinkProps {
  caseContent: CaseContent

  className?: string
}

export default function NextCaseLink({
  caseContent,

  className,
}: NextCaseLinkProps) {
  const nextProjectLabel = useMicroCopy('text.next_project')
  const caseTo = useCaseLink(caseContent)

  const renderImage = caseContent.nextCaseTeaserImage?.fluid
    ? caseContent.nextCaseTeaserImage
    : caseContent.heroImage?.fluid
    ? caseContent.heroImage
    : caseContent.teaserImage

  const fluidImage = renderImage?.fluid

  if (!fluidImage) {
    return null
  }

  return (
    <LayoutContainer className={classNames(styles.container, className)}>
      <InternalLink
        className={styles.link}
        to={caseTo}
        segmentdata={{
          anchor_text: caseContent?.client?.name,
          position: 'body',
          url: caseTo,
        }}
      >
        <span className={styles.title}>{nextProjectLabel}</span>

        <Img
          className={styles.image}
          fluid={fluidImage}
          alt={renderImage?.description || renderImage?.title}
        />

        <span className={styles.projectName}>
          {caseContent?.client?.name} -{' '}
          {caseContent.teaserTitle ?? caseContent.projectName}
          <SvgArrowRight className={styles.projectNameIcon} />
        </span>
      </InternalLink>
    </LayoutContainer>
  )
}
