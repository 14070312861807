import React from 'react'

interface PageDebugMetaOutputProps {
  page: {
    __typename?: string
    id?: string
    contentful_id?: string
  }
}

export default function PageDebugMetaOutput({
  page,
}: PageDebugMetaOutputProps) {
  const { __typename, id, contentful_id } = page

  return (
    <div
      data-page-debug-meta
      dangerouslySetInnerHTML={{
        __html: `<!--
  = Page Debug Meta Output =
  ${__typename ? `TypeName: ${__typename}` : 'unknown'}
  ${id ? `ID: ${id}` : 'unknown'}
  ${contentful_id ? `Contentful ID: ${contentful_id}` : 'unknown'}
  -->`,
      }}
    />
  )
}
