import { graphql } from 'gatsby'
import React from 'react'

import { CaseContent } from '../../@import-types/contentful/Case.types'
import ContentArea from '../../components/ContentArea'
import Ingress from '../../components/Ingress'
import Layout from '../../components/Layout'
import NextCaseLink from '../../components/NextCaseLink'
import PageDebugMetaOutput from '../../components/_functional/PageDebugMetaOutput'
import ImageHero from '../../components/_heroes/ImageHero'
import EmployeeGrid from '../../components/_layouts/EmployeeGrid'
import { RootPageQuery } from '../../graphql/global.graphql'
import { getCaseColor, getComplementaryColor } from '../../utils/colorUtils'

import CaseInfoAndLink from './CaseInfoAndLink'

type CasePageProps = RootPageQuery<{
  contentfulCasePage: CaseContent
  nextCase: CaseContent
}>

export default function CasePage({ data, pageContext }: CasePageProps) {
  const { contentfulCasePage, nextCase } = data

  const {
    projectName,
    smallerHeading,
    shortDescription,
    heroImage,
    introduction,
    content,
    involvedEmployees,
  } = contentfulCasePage

  const hasNextCase = Boolean(
    nextCase && (nextCase.heroImage || nextCase.teaserImage)
  )

  const caseColor = getCaseColor(contentfulCasePage)
  const caseTextColor = getComplementaryColor(caseColor)

  return (
    <Layout
      data={data}
      basePageContent={data.contentfulCasePage}
      noFooter={hasNextCase}
      pageContext={pageContext}
      pageBackgroundColor={caseColor}
      pageTextColor={caseTextColor}
    >
      <PageDebugMetaOutput page={contentfulCasePage} />

      <ImageHero
        title={projectName}
        subtitle={shortDescription}
        backgroundColor={caseColor}
        image={heroImage ?? null}
        smallerMobileHeading={smallerHeading}
      />

      {introduction && (
        <Ingress
          text={introduction}
          contentArea={content}
          projectName={projectName}
        />
      )}

      <CaseInfoAndLink caseContent={contentfulCasePage} />

      {content && <ContentArea content={content} />}

      {involvedEmployees && <EmployeeGrid employees={involvedEmployees} />}

      {hasNextCase && <NextCaseLink caseContent={nextCase} />}
    </Layout>
  )
}

export const pageQuery = graphql`
  query CaseBySlug(
    $id: String!
    $nextId: String
    $startPageId: String!
    $locale: String!
  ) {
    contentfulCasePage(id: { eq: $id }) {
      ...BasePageFragment

      projectName
      smallerHeading
      launchDate
      projectLink

      shortDescription

      introduction {
        json
      }

      heroImage {
        title
        description
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      caseColor
      client {
        color
      }

      content {
        __typename

        ... on Node {
          ...ContentAreaFragment
        }
      }

      involvedEmployees {
        ...EmployeeTeaserFragment
      }
    }

    nextCase: contentfulCasePage(id: { eq: $nextId }) {
      ...CaseTeaserFragment
    }

    ...GlobalFragment
  }
`
